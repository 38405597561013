import { isNull } from 'lodash';

const isReadOnlyRole = (userRoles) => {
  const readOnlyRoles = ['viewer']; // roles that only have read rights

  return readOnlyRoles.some((readOnlyRole) => userRoles.includes(readOnlyRole));
};

const getTopLevelUserRole = (userRoles) => {
  if (userRoles.includes('owner')) {
    return 'owner';
  }

  if (userRoles.includes('admin')) {
    return 'admin';
  }

  if (userRoles.includes('manager')) {
    return 'manager';
  }

  return null;
};

/*
  Keycloak uses role inheritance, that means that "owner" role has
  "admin" role and admin role has "manager" role. User can have multiple
  roles, for example a user can be "admin" and "boost admin".
  Read only users are users with low level rights (some clients etc.)
  and this flag is used to turn off some options and sections.
*/
export default function getUserRole(userRoles) {
  const topLevelUserRole = getTopLevelUserRole(userRoles);

  return {
    isOwner: topLevelUserRole === 'owner', // inherits: [user, viewer, developer, manager, boost_admin, admin]
    isAdmin: topLevelUserRole === 'admin', // inherits: [user, viewer, developer, manager]
    isManager: topLevelUserRole === 'manager', // inherits: [user, viewer, developer]
    isReadOnlyUser: isNull(topLevelUserRole) && isReadOnlyRole(userRoles),
    isBoostOnlyUser: userRoles.length === 1 && userRoles.includes('boost_admin'), // User with access only for boost
    isBoostAdmin: userRoles.includes('boost_admin'),
    userRoles,
  };
}
