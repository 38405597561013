import { getUserRole } from '../utility';

export default {
  companies: (state) => state.companies,
  partners: (state) => state.partners,
  games: (state) => state.games,
  selectedCompany: (state) => state.selectedCompany,
  selectedPartner: (state) => state.selectedPartner,
  detailsModalToggler: (state) => state.toggleDetailsModal,
  detailsModalConfig: (state) => state.detailsModalConfig,
  companyGames: (state) => state.companyGames,
  currencies: (state) => state.currencies,
  languages: (state) => state.languages,
  selectedGame: (state) => state.selectedGame,
  companyGameRounds: (state) => state.companyGameRounds,
  companyGameDetails: (state) => state.companyGameDetails,
  selectedRound: (state) => state.selectedRound,
  rowsCount: (state) => state.rowsCount,
  players: (state) => state.players,
  tags: (state) => state.tags,
  playerTags: (state) => state.playerTags,
  playerTagDetails: (state) => state.playerTagDetails,
  assets: (state) => state.assets,
  selectedAssetGame: (state) => state.selectedAssetGame,
  thumbnail: (state) => state.thumbnail,
  bootstrap: (state) => state.bootstrap,
  reportsGames: (state) => state.reportsGames,
  selectedReportsGame: (state) => state.selectedReportsGame,
  selectedPromotion: (state) => state.selectedPromotion,
  promotions: (state) => state.promotions,
  promotionPlayers: (state) => state.promotionPlayers,
  promotionDetails: (state) => state.promotionDetails,
  reportsPlayers: (state) => state.reportsPlayers,
  translation: (state) => (key) => state.translations[key],
  translations: (state) => state.translations,
  pusherMessage: (state) => state.pusherMessage,
  snackbarNotification: (state) => state.snackbarNotification,
  importFile: (state) => state.importFile,
  acceptHeader: (state) => state.acceptHeader,
  isMobile: (state) => state.isMobile,
  toolbarTitle: (state) => state.toolbarTitle,
  cancelControllers: (state) => state.cancelControllers,
  loading: (state) => state.loading,
  currencyValidation: (state) => state.currencyValidation,
  promotionErrorList: (state) => state.promotionErrorList,
  isUnifiedCurrency: (state) => state.unifiedCurrency,
  isExcludeInternal: (state) => state.excludeInternal,
  gameAssetsDetails: (state) => state.gameAssetsDetails,
  assetsUploadDialog: (state) => state.uploadAssetsDialog,
  userRole: (state) => {
    const { roles } = state.keycloak.resourceAccess['casino-backoffice'];

    return getUserRole(roles);
  },
  keycloakState: (state) => state.keycloak,
  navigation: (state) => state.navigation,
  deviceDefinitions(getters) {
    return {
      grow: getters.isMobile,
      background: getters.isMobile ? 'background' : 'transparent',
      tabs: getters.isMobile ? 'pa-0' : 'pa-4',
      view: getters.isMobile ? 'pa-4' : 'pa-0',
    };
  },
  footerProps: (state, getters) => ({
    'items-per-page-options': state.rowsCount,
    'items-per-page-text': getters.isMobile ? state.translations.rows : state.translations.rowsPerPage,
    'page-text': state.translations.pageText,
  }),
  tournamentDetails: (state) => state.tournamentDetails,
  boostBootstrap: (state) => state.boostBootstrap,
  boostCompanies: (state) => state.boostCompanies,
  isBoostUnavailable: (state) => state.boostUnavailable,
  authorizedNavigation: (_, getters) => getters.navigation.filter(
    (item) => item.roles.some((role) => getters.userRole.userRoles.includes(role)),
  ),
};
