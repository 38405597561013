<template>
  <v-app>
    <div id="app"
         style="height: 100%">
      <TemplateDefault v-if="loaded" />
      <v-snackbar v-model="snackbar"
                  :timeout="timeout"
                  :text="text"
                  :color="color">
        <div class="mx-6 text-center"
             :class="getLabelPosition">
          {{snackbarLabel}}
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn class="text-none"
                 color="primary"
                 v-if="download"
                 text
                 v-bind="attrs"
                 @click="getExportedData(pusherMessage.data.url)">
            <LocalizedLabel>download</LocalizedLabel>
          </v-btn>
          <v-btn class="text-none"
                 color="error"
                 v-if="promotionError"
                 text
                 v-bind="attrs"
                 @click="getPromotionErrorList()">
            <LocalizedLabel>showList</LocalizedLabel>
          </v-btn>
          <v-divider vertical
                     v-if="isSnackbarPersistent"
                     class="mx-2"></v-divider>
          <v-btn icon
                 v-if="isSnackbarPersistent"
                 class="mx-2"
                 @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import isMobile from 'ismobilejs';
import {
  filter,
  includes,
  find,
  map,
} from 'lodash';
import TemplateDefault from '@/layouts/TemplateDefault';

export default {
  name: 'App',
  components: {
    TemplateDefault,
  },
  data() {
    return {
      close: false,
      color: '',
      download: false,
      isMobile: false,
      loaded: false,
      snackbar: false,
      snackbarLabel: '',
      text: false,
      promotionError: false,
      timeout: 3000,
    };
  },
  methods: {
    ...mapActions([
      'finishGlobalLoading',
      'getBootstrap',
      'setMobileDetection',
      'setPromotionErrorList',
      'setSelectedCompany',
      'getBoostBootstrap',
    ]),
    getExportedData(url) {
      window.location.href = url;
      this.snackbar = false;
    },
    getPromotionErrorList() {
      this.setPromotionErrorList({
        data: this.snackbarNotification.promotionErrorList.details,
        dialog: true,
      });
    },
    activateSnackbar(value) {
      this.text = value.text;
      this.download = value.download;
      this.close = value.close;
      this.snackbarLabel = value.label;
      this.snackbar = true;
      this.color = value.color;
      this.promotionError = value.promotionError;
    },
  },
  computed: {
    ...mapGetters([
      'pusherMessage',
      'snackbarNotification',
      'translations',
      'userRole',
    ]),
    getLabelPosition() {
      return this.text ? 'text-center' : '';
    },
    isSnackbarPersistent() {
      if (this.close || this.download) {
        return true;
      }
      return false;
    },
  },
  async created() {
    const response = await this.getBootstrap();
    if (this.userRole.isBoostAdmin) {
      await this.getBoostBootstrap();
    }
    const activeCompanies = filter(response.user.tenants, 'active');
    const queryCompany = this.$route.query.company || this.$route.query.tenantId;
    const hasQueryCompany = find(activeCompanies,
      (company) => includes(company.id, queryCompany));
    await this.setMobileDetection(isMobile().any);

    if (!hasQueryCompany && !this.$route.meta.tenantMultipick) {
      delete this.$route.query.company;
      delete this.$route.query.tenantId;
      await this.setSelectedCompany(activeCompanies[0]);
      this.loaded = true;
    } else if (this.$route.meta.tenantMultipick) {
      const hasMultiQueryCompany = filter(response.user.tenants,
        (company) => includes(queryCompany, company.id));
      const { query } = this.$route;
      query.tenantId = map(hasMultiQueryCompany, 'id');
      this.$router.replace({ query }, () => { });
      this.loaded = true;
    } else {
      this.loaded = true;
    }
    this.finishGlobalLoading();
  },
  watch: {
    pusherMessage(newValue) {
      this.timeout = newValue.timeout || -1;
      this.activateSnackbar(newValue);
    },
    snackbarNotification(newValue) {
      if (newValue) {
        this.timeout = newValue.timeout || 3000;
        this.activateSnackbar(newValue);
      }
    },
  },
};
</script>

<style lang="scss">
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
